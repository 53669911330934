import styled from "@emotion/styled";
import { FileDownloadRounded } from "@mui/icons-material";
import { Button, Stack } from "@mui/material";

export default function DownloadButton(props) {

    return (
      <a href={props.link}>
        <Stack className='downloadButton' spacing={1} direction='row' alignContent='flex-start' alignItems='center'>
          {props.icon}<span>{props.title}</span></Stack></a>
    )
}