import { Link } from "react-router-dom";
import Page from "./Page";
import { Box, Grid, IconButton, Rating, Stack } from "@mui/material";
import { ChevronLeftRounded, ChevronRightRounded, FileDownloadRounded } from "@mui/icons-material";
import DownloadButton from "../DownloadButton";

export default function ProductPage({product, previous, next}) {

    let features, featuresWithHeadings, custom = null
    if (product.features) {
        features = (
            <ul className='productPageList'>
            {product.features.map((feature, i) => {
                return <li key={i}>{feature}</li>
            })}
            </ul>
        );
    }

    if (product.featuresWithHeadings) {
        featuresWithHeadings = product.featuresWithHeadings.map((feature, i) => {
            return (
                <div key={i}>
                    <h4 className='productPageSubHeading'>{feature.heading}</h4>
                    <ul className='productPageList'>
                    {feature.items.map((item, i) => {
                        return <li key={i}>{item}</li>
                    })}
                    </ul>
                </div>
            )
        })
    }

    if (product.id === 'cockpit') {
        custom = (
            <>
            <h4 className='productPageSubHeading'>Documentation</h4>
            <Box className='docsTile'>
                <p>There are many gauges available in COCKPIT HD and they use different controls. Some things may be obvious and some may be not. Please use this little cheat sheet as a reference on how to control the app.</p>
                <DownloadButton link='/download/RemoteFlight_COCKPIT_HELP.pdf' icon={<FileDownloadRounded />} title='Download COCKPIT HD Manual'/>
            </Box>            
            <Grid container spacing={{xs:1, sm: 1, md: 1.5}} alignItems='stretch' direction="row" justifyContent="center">
                <Grid item xs={12}>
                    <h4 className='productPageSubHeading'>COCKPIT HD BASIC GAUGES</h4>
                </Grid>
                {
                    product.basicGauges.map((gauge, i) => {
                        return <CockpitGaugeTile key={'basicGauge' + i} {...gauge}/>
                    })
                }
                <Grid item xs={12}>
                    <h4 className='productPageSubHeading'>COCKPIT HD ADVANCED GAUGES (AVAILABLE AS IN-APP-PURCHASE)</h4>
                </Grid>
                {
                    product.advancedGauges.map((gauge, i) => {
                        return <CockpitGaugeTile key={'basicGauge' + i} {...gauge}/>
                    })
                }
            </Grid>
            </>
        )
    } else if (product.id === 'glass') {
        custom = (
            <>
            <Grid container spacing={{xs:1, sm: 1, md: 1.5}} alignItems='stretch' direction="row" justifyContent="center">
                <Grid item xs={12} sm={12} md={6}>
                    <h4 className='productPageSubHeading'>Available Radio functions</h4>
                    <Box className='docsTile'>
                        <ul className='productPageListSmall'>
                        {
                            product.RADIOS.map((radio, i) => {
                                return <li key={i}>{radio}</li>
                            })
                        }
                        </ul>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                    <h4 className='productPageSubHeading'>RMI Functions</h4>
                    <Box className='docsTile'>
                        <ul className='productPageListSmall'>
                        {
                            product.RMI.map((rmi, i) => {
                                return <li key={i}>{rmi}</li>
                            })
                        }
                        </ul>
                    </Box>
                </Grid>
            </Grid>
            </>
        )
    } else if (product.id === 'maphd') {
        custom = (
            <>
            <Grid container columns={8} spacing={{xs:1, sm: 1, md: 1}} alignItems='stretch' direction="row" justifyContent="flex-start">
                <Grid item xs={8}>
                    <h4 className='productPageSubHeading'>MapHD Aircraft Icons</h4>
                </Grid>
                {
                    product.planes.map((plane, i) => {
                        return <MapPlaneTile key={'plane' + i} {...plane}/>
                    })
                }
            </Grid>
            </>
        )
    }

    const productBarSticky = (
        <Stack className="productBarSticky" direction={{md: 'row', sm:'row'}} justifyContent="space-between" spacing={3} alignItems="center">
            <h3><strong>{product.name}</strong></h3>
            <Stack direction='row' spacing={1} alignItems="center">
                <a href={product.appstoreLink} target='_blank' rel='noreferrer'>
                <img src='/images/appstore2.svg' alt='Download from AppStore' width='100px'/>
                </a>
                <IconButton component={Link} to={previous.link}><ChevronLeftRounded/></IconButton>
                <IconButton component={Link} to={next.link}><ChevronRightRounded/></IconButton>
            </Stack>
        </Stack>
    )

    return (
        <Page>
            <Box className='productDetailTile'>
            <Grid container spacing={{xs:1, sm: 2, md: 3}} columns={12}>

                <Grid item xs={12}>
                    <Stack direction={{md: 'row', sm:'column'}} justifyContent="space-between" spacing={3} alignItems="center">
                        <h2 className='productDetailHeading'><strong>{product.name}</strong></h2>
                        <Stack direction='row' spacing={1} alignItems="center">
                            <a href={product.appstoreLink} target='_blank' rel='noreferrer'>
                            <img src='/images/appstore2.svg' alt='Download from AppStore' width='120px'/>
                            </a>
                            <IconButton component={Link} to={previous.link}><ChevronLeftRounded/></IconButton>
                            <IconButton component={Link} to={next.link}><ChevronRightRounded/></IconButton>
                        </Stack>
                    </Stack>
                </Grid>

                <Grid item sm={12} md={6} lg={6}>    
                    <img width='100%' src={'/images/' + product.titleImage} alt={product.name} />
                </Grid>
                <Grid item sm={12} md={6} lg={6}>
                    <Stack direction='column' justifyContent="center" alignItems="flex-start">
                        <p className='productPageText'>{product.productText}</p>
                        <h3 className='productPageSubHeading'>Features</h3>
                        {features}
                        {featuresWithHeadings}
                    </Stack>
                </Grid>

            </Grid>
            </Box>
            
            {custom}

            <Grid container columns={12} spacing={2} style={{paddingTop:'1rem'}} justifyContent="center" alignItems="flex-start">
                    <Reviews product={product}/>
                    <Compatibility product={product}/>
            </Grid>
            
            
        </Page>
    )
}

export function Reviews(p) {
    if (p.product.reviews) {
        return (
            <Grid item sm={12} md={p.product.compatibility ? 6 : 12} lg={p.product.compatibility ? 6 : 12}>
            <Box>
                <h4 className='productPageSubHeading'>User Reviews</h4>
                <Stack direction='column' spacing={1} alignItems='flex-start'>
                {p.product.reviews.map((review, i) => {
                    return <Review key={i} {...review}/>
                })}
                </Stack>
                
            </Box>
            </Grid>
        )
    }
}

export function Review(review) {
    return (
        <Box className='reviewTile'>
            <Stack direction='row' spacing={1} alignItems='center'>
            <h5>{review.title}</h5>
            <Rating size='small' value={review.stars} readOnly />
            </Stack>
            <p>{review.text}</p>
        </Box>
    )

}

export function Compatibility(p) {
    if (p.product.compatibility) {
        return (
            <Grid key='compatibility' item sm={12} md={p.product.reviews ? 6 : 12} lg={p.product.reviews ? 6 : 12}>
            <Box>
                <h4 className='productPageSubHeading'>Compatibility</h4>
                <Stack direction='column' spacing={1}>
                {p.product.compatibility.map((compatibility, i) => {
                    return <Box key={'compatibility' + i} className='compatibilityTile'>{compatibility}</Box>
                })}
                </Stack>
            </Box>
            </Grid>
        )
    }
}

export function CockpitGaugeTile(gauge) {
    return (
        <Grid item xs={6} sm={6} md={4} lg={2}>
            <div className='cockpitGaugeTile'>
            <h5>{gauge.title}</h5>
            <img src={'/images/cockpit/' + gauge.image} alt={gauge.title} width='100%'/>
            </div>
        </Grid>
    )
}

export function MapPlaneTile(plane) {
    return (
        <Grid item xs={4} sm={2.5} md={1.5} lg={1}>
            <div className='cockpitGaugeTile'>
            <h5>{plane.title}</h5>
            <img src={'/images/map/' + plane.image} alt={plane.title} width='100%'/>
            </div>
        </Grid>
    )
}