import { Box, Button, Card, Grid, Stack } from "@mui/material";
import Page from "./Page";
import { Link } from "react-router-dom";
import styled from "@emotion/styled";
import RFButton from "../RFButton";

export default function Home({products}) {
    
    return (
        <Page>
            <Grid container 
                spacing={{xs:1, sm: 2, md: 3}} 
                alignItems='stretch'
                >
                
            {
                Object.keys(products).map((product, i) => 
                    
                    <HomeTile key={i} product={products[product]} />
                )
            }
            </Grid>
        </Page>
    )
}

function HomeTile({product, previous, next}) {
    return (
        <Grid item xs={12} sm={12} md={6} lg={4}>
            <Link to={product.link}>
                <Box className='homeTile'>
                    <Stack style={{marginTop:0}} direction='column' spacing={{xs:1, sm:1, md:2}} alignItems='center'>
                        <div style={{overflow: 'hidden', minWidth: '280px', minHeight: '120px', maxHeight: '220px', textAlign:'center'}}>
                            <img width='200px' src={'/images/' + product.titleImage} alt={product.name}/>
                        </div>
                        <Stack style={{marginTop:0}} direction='column' justifyContent="center" alignItems='center'>
                            <h3 className='homeTileHeading'>{product.name}</h3>
                            <p className='homeTileDescription'>{product.description}</p>
                            <div className='homeTileProductButton'>Product page</div>
                        </Stack>
                    </Stack>
                </Box>
            </Link>
        </Grid>
    )
}