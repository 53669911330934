import { Container, ThemeProvider, createTheme } from "@mui/material";
import { BrowserRouter, RouterProvider, Route, Routes, createBrowserRouter, ScrollRestoration} from "react-router-dom";
import Home from "./Pages/Home";
import Server from "./Pages/Server";
import ProductPage from "./Pages/ProductPage";
import Support from "./Pages/Support";
import './App.css';
import { getProducts } from "./data";
import { useState } from "react";
import Faq from "./Pages/Faq";

function App() {
  
  const [data, setData] = useState(getProducts())

  const theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 320,
        md: 680,
        lg: 1200,
        xl: 1536,
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        
          <Routes>
            <Route path='/' element={<Home products={data}/>}/>
            <Route path='/contact' element={<Home products={data}/> }/>
            <Route path='/faq' element={<Home products={data}/> }/>
            <Route path='/about' element={<Home products={data}/> }/>
            <Route path='/server' element={<Server />}/>
            <Route path='/downloads' element={<Server />}/>
            <Route path='/support' element={<Support />}/>

            {Object.keys(data).map((product, i) =>
              <Route path={data[product].link} 
                element={<ProductPage 
                    product={data[product]} 
                    next={(i < Object.values(data).length - 1) ? Object.values(data)[i + 1] : Object.values(data)[0]}
                    previous={(i === 0) ? Object.values(data)[Object.values(data).length - 1] : Object.values(data)[i - 1]}  
                  />} 
                key={i}/>  
            )}

            <Route path='*' element={<h1>Not Found</h1>}/>
          </Routes>
          
      </BrowserRouter>
    </ThemeProvider>
  )
}

export default App;
