import { Box, Button, Container, Grid, Stack, TextField } from "@mui/material";
import { Link, NavLink } from "react-router-dom";
import useBreakpoint from "../UseBreakpoint";
import StickyBox from "react-sticky-box";
import { useWindowScroll } from "@uidotdev/usehooks";
import styled from "@emotion/styled";

export default function Page(props) {
    const bp = useBreakpoint()

    const [{ x, y }, scrollTo] = useWindowScroll()

    let addLink = null
    let menuBarClassName = 'menuBar'
    let advert = 'The ultimate home cockpit on your iPad or iPhone'
    let productBar = null
    let menuBarHeight = document.getElementById('menuBarSticky') ? document.getElementById('menuBarSticky').offsetHeight : 0
    if (document.getElementById('header')) {
        let headerHeight = document.getElementById('header').offsetHeight
        if (y > headerHeight * 0.6) {
            addLink = <h3 className='logoSmall'><strong>Remote</strong>Flight</h3>
            advert = null
            menuBarClassName += ' menuBarShadow'
        }
        if (y > headerHeight * 0.6 + menuBarHeight) {
            productBar = props.productBarSticky
        }
    }
    
    let logo = <Stack sx={{display:{sm:'none', md:'block'}}} direction={{md: 'row', sm:'column'}}><Link to='/'><p className='advert'>{addLink}</p></Link><div><p className='advert'>{advert}</p></div></Stack>
    
    const WhiteButton = styled(Button)({
        boxShadow: 'none',
        fontWeight: 700,
        backgroundColor: '#ffffff11',
        border: '1px solid #ffffff44',
        padding: '0.4rem 1rem',
        color: '#ffffffcc',
        '&:hover': {
            backgroundColor: '#ffffff33',
            color: '#ffffff',
            boxShadow: 'none',
          },
    })

    const WhiteTextFIeld = styled(TextField)({
        '& label': {
          color: '#ffffff55',
        },
        '& input': {
            backgroundColor: 'rgba(0,0,0,0.4)',
            color: '#ffffff',
        },
        '& .MuiInput-underline:after': {
          borderBottomColor: '#ffffff',
        },
        '& .MuiOutlinedInput-root': {
          '& fieldset': {
            borderColor: '#ffffff44',
          },
          '&:hover fieldset': {
            borderColor: '#ffffff',
          },
          '&.Mui-focused fieldset': {
            borderColor: '#ffffff',
          },
        },
      });

    return (
        <Box className='main'>
            
            <Box className='contentWrap'>
                <Container>
                
                    <Grid id='header' container spacing={2} justifyContent={{lg:'space-between', md:'space-between', sm:'center', xs:'center'}}>
                        <Grid item>
                            <Link to='/'><h1 className='logo' style={{padding: '1rem 0'}}><strong>Remote</strong>Flight</h1></Link>
                        </Grid>
                    </Grid>
                    
                    
                    <StickyBox style={{zIndex:'99'}}>
                        <Stack id='menuBarSticky' direction={{md:'row', sm: 'column'}} alignItems='center' justifyContent={{md:'space-between', sm:'center'}} spacing={0.5} className={menuBarClassName}>
                            {logo}
                            <Stack direction='row' spacing={0.5}>
                                <NavLink className='menuItem' to='/'>Apps</NavLink>
                                <NavLink className='menuItem' to='/downloads'>Downloads</NavLink>
                                <NavLink className='menuItem' to='/support'>Support</NavLink>
                                
                            </Stack>
                        </Stack>
                    </StickyBox>
                    <StickyBox offsetTop={menuBarHeight}>
                        {props.productBarSticky}
                    </StickyBox>
                    {props.children}
                

                    <Box className='contact'>
                        <h4 className='productPageSubHeading'>Contact</h4>
                        <Stack direction={{lg:'row', md:'column', sm:'column'}} spacing={2}>
                            
                            <Stack className='compatibilityTile' direction={{md:'row', sm:'sm', xs:'column'}} spacing={1} alignItems='center'>
                                    <span>Write an email</span><a style={{fontWeight:'800'}} href='mailto:support@remoteflight.net'>support@remoteflight.net</a>
                            </Stack>
                            <Box className='compatibilityTile'>
                            <form action="//remoteflight.us3.list-manage.com/subscribe/post?u=8b93c0ba40081ca6af168776e&amp;id=3d38c62ac9" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_blank" novalidate="">
                                <Stack direction={{md:'row', sm:'column'}} spacing={2} alignItems='center'>

                                    <span>Subscribe to our mailing list </span>
                                    <WhiteTextFIeld name="email" size='small' id='mce-EMAIL' placeholder="Email address" required />
                                    <WhiteButton type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe">Subscribe</WhiteButton>
                                    <input type='hidden' name="b_8b93c0ba40081ca6af168776e_3d38c62ac9" />
                                </Stack>
                            </form>
                            </Box>
                        </Stack>
                    </Box>
                </Container>
            </Box>

            
            
            <Box className='footer'>
                <Container>
                    <p className='footerRF'>
                        RemoteFlight - Beautiful and efficient Flight Simulator and X-Plane gauges for Apple devices
                    </p>
                    <p className='footerSmallPrint'>
                        Designed and developed by Vlada Oupicky. Copyright © Inputwish s.r.o. 2012 - 2024
                    </p>
                    <p className='footerSmallPrint'>
                        Microsoft® Flight Simulator is trademark of Microsoft Corporation
                    </p>
                    <p className='footerSmallPrint'>
                        Apple, iPad, iPhone, iPod touch, Apple Watch and iTunes are trademarks of Apple Inc., registered in the U.S. and other countries. App Store is a service mark of Apple Inc.
                    </p>
                </Container>
            </Box>
        </Box>
    )
}